import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { useState } from "react";
import { useEffect } from "react";
import operadorService from "services/operador";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/"
  },

  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/"
  },
  {
    title: "Billing",
    icon: ShopOutlined,
    path: "/"
  },
  {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    path: "/"
  }
]

export const NavProfile = ({ signOut }) => {

  const [operador, setOperador] = useState(null)

  useEffect(() => {
    operadorService.get().then(dados => setOperador(dados))
  }, [])

  return (
    <div style={{ display: 'flex', alignItems: 'center' }} >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginLeft: 8 }}  >
        <h4 style={{ textTransform: 'capitalize' }}  >{operador?.username}</h4>
        <span style={{ lineHeight: 0 }} >{operador?.email}</span>
      </div>
    </div>
  );
}

export default connect(null, { signOut })(NavProfile)
