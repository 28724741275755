import {
  DashboardOutlined,
  ContactsOutlined,
  UsergroupAddOutlined,
  MessageOutlined,
  UnorderedListOutlined,
  WechatOutlined,
  GroupOutlined,
  SettingOutlined,
  PieChartOutlined,
  InsertRowAboveOutlined,
  FileDoneOutlined,
  RobotOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'


const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'home',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'announcement',
    path: `${APP_PREFIX_PATH}/announcement`,
    title: 'announcement.title.header',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'announcement-register',
        path: `${APP_PREFIX_PATH}/announcement/register`,
        title: 'announcement.register',
        icon: InsertRowAboveOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'announcement-reports',
        path: `${APP_PREFIX_PATH}/announcement/reports`,
        title: 'announcement.reports',
        icon: PieChartOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
  {
    key: 'channel',
    path: `${APP_PREFIX_PATH}/canal`,
    title: 'channel.title.header',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'channel-channel',
        path: `${APP_PREFIX_PATH}/canal/canais`,
        title: 'channel.title',
        icon: WechatOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'channel-grupo',
        path: `${APP_PREFIX_PATH}/canal/grupos`,
        title: 'channel.group',
        icon: GroupOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
  {
    key: 'contatos',
    path: `${APP_PREFIX_PATH}/contatos`,
    title: 'contact.title.header',
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'contato-contato',
        path: `${APP_PREFIX_PATH}/contatos/contatos`,
        title: 'contact.title',
        icon: ContactsOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'contato-grupo',
        path: `${APP_PREFIX_PATH}/contatos/grupos`,
        title: 'contact.group',
        icon: UsergroupAddOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
  // {
  //   key: 'bot',
  //   path: `${APP_PREFIX_PATH}/bot`,
  //   title: 'bot.group.title',
  //   icon: DashboardOutlined,
  //   breadcrumb: true,
  //   submenu: [
  //     {
  //       key: 'bot',
  //       path: `${APP_PREFIX_PATH}/bot`,
  //       title: 'bot.title',
  //       icon: RobotOutlined,
  //       breadcrumb: false,
  //       submenu: [
  //         {
  //           key: 'bot-new',
  //           path: `${APP_PREFIX_PATH}/bot/new`,
  //           title: 'bot.create.title',
  //           icon: PlusCircleOutlined,
  //           breadcrumb: false,
  //           submenu: []
  //         },
  //         {
  //           key: 'bot-list',
  //           path: `${APP_PREFIX_PATH}/bot/list`,
  //           title: 'bot.list.title',
  //           icon: UnorderedListOutlined,
  //           breadcrumb: false,
  //           submenu: []
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    key: 'campanhas',
    path: `${APP_PREFIX_PATH}/campanhas`,
    title: 'campaigns.title',
    icon: MessageOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'campaigns-list',
        path: `${APP_PREFIX_PATH}/campanhas/listagem`,
        title: 'campaigns.title',
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'campaigns-channel-list',
        path: `${APP_PREFIX_PATH}/campanhas/canal`,
        title: 'campaigns.channel.title',
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'campaigns-logs-top',
        path: `${APP_PREFIX_PATH}/campanhas/logs`,
        title: 'campaigns.logs.top',
        icon: UnorderedListOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'campaigns-logs',
            path: `${APP_PREFIX_PATH}/campanhas/logs`,
            title: 'campaigns.logs',
            icon: UnorderedListOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'campaigns-sms-answer',
            path: `${APP_PREFIX_PATH}/campanhas/sms-answer`,
            title: 'campaigns.logs.sms.answer',
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: []
          }
        ]
      },
    ]
  },
  {
    key: 'config',
    path: `${APP_PREFIX_PATH}/config`,
    title: 'config.title.header',
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'config-logs',
        path: `${APP_PREFIX_PATH}/config/logs`,
        title: 'config.logs.title',
        icon: UnorderedListOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'config-list',
        path: `${APP_PREFIX_PATH}/config/list`,
        title: 'config.list',
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  }
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
