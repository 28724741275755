import React, { Component, useEffect, useCallback, useState } from 'react'
import { Row, Col, Card, Avatar, Button, Modal, Form, Input } from 'antd';
import { Icon } from 'components/util-components/Icon'
import {
	PhoneOutlined
} from '@ant-design/icons';
import { QRCodeCanvas } from 'qrcode.react';
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import Flex from 'components/shared-components/Flex'
import botService from 'services/bot';
import { useParams } from 'react-router-dom';

const ProfileInfo = props => {

	const { id } = useParams()
	const [bot, setBot] = useState({})
	const [load, setLoad] = useState(false)
	const [openSendMenssagem, setOpenSendMenssagem] = useState(true);
	const { confirm } = Modal



	const buscarBot = useCallback(
		async () => {
			try {
				const data = await botService.getBot(id)
				setBot(data)
			} catch (error) {

			}
		},
		[id],
	)

	const delisgar = useCallback(async () => {
		setLoad(true)
		try {
			await botService.desligar(id)
		} catch (error) {

		}
		setLoad(false)
	}, [id])

	const ligar = useCallback(async () => {
		setLoad(true)
		try {
			await botService.ligar(id)
		} catch (error) {

		}
		setLoad(false)
	}, [id])


	const handlerOff = async () => {
		confirm({
			title: `Deseja desligar esse bot?`,
			content: `O bot ${id} será desliga!`,
			onOk: delisgar,
			okText: "Desligar",
			cancelText: "Cancelar"
		})
	}
	const handleOn = async () => {
		confirm({
			title: `Deseja ligar esse bot?`,
			content: `O bot ${id} será ligado!`,
			onOk: ligar,
			okText: "Ligar",
			cancelText: "Cancelar"
		})
	}

	const onFinish = (values) => {
		console.log('Success:', values);
		Modal.destroyAll();
	};

	const handleSendMessage = async () => {
		confirm({
			title: `Enviar mensagem de teste`,
			content: <>
				<br />
				<br />
				<Form
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
				>
					<Form.Item
						name="numero"
						rules={[{ required: true, message: 'por favor, informe um número' }]}
					>
						<Input placeholder='Destinatario' type='number' />
					</Form.Item>

					<Form.Item
						name="mensagem"
						rules={[{ required: true, message: 'por favor, informe uma mensagem' }]}
					>
						<Input.TextArea placeholder='Escreva sua mensagem!' />
					</Form.Item>

					<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
						<Button type="primary" htmlType="submit">
							Enviar
						</Button>
					</Form.Item>
				</Form>
			</>,
			onOk: hideModal,
			okText: "Fechar",
			okCancel: false,

		})
	}

	const hideModal = () => {
		setOpenSendMenssagem(false);
	};

	useEffect(() => {
		buscarBot()
		setInterval(() => {
			buscarBot()
		}, 5000);
	}, [id, buscarBot])

	return (
		<Card>
			<Row justify="center">
				<Col sm={24} md={23}>
					<div className="d-md-flex">
						<div className="rounded p-2 bg-white shadow-sm mx-auto" style={{ 'marginTop': '-3.5rem', 'maxWidth': `${props.avatarSize + 16}px`, 'maxHeight': `${props.avatarSize + 16}px` }}>
							<Avatar shape="square" size={props.avatarSize} src={bot?.foto_perfil_whats} />
						</div>
						<div className="ml-md-4 w-100">
							<Flex alignItems="center" mobileFlex={false} className="mb-3 text-md-left text-center">
								<h2 className="mb-0 mt-md-0 mt-2">{bot?.name}</h2>
								<div className="ml-md-3 mt-3 mt-md-0">
									{!['offline', 'desligado'].includes(bot?.status) ?
										<Button size="small" type="primary" onClick={handlerOff} loading={load}>Desligar</Button> :
										<Button size="small" type="primary" onClick={handleOn} loading={load}>Ligar</Button>
									}
									{/* <Button size="small"
										disabled={bot?.status !== 'CONNECTED'}
										className="ml-2"
										loading={load}
										onClick={handleSendMessage}
									>Mensagem</Button> */}

								</div>
							</Flex>
							<Row gutter="16">
								<Col sm={24} md={8}>
									<p className="mt-0 mr-3 text-muted text-md-left text-center">
										Faça o scan do qrCode do WhatsApp!
									</p>
								</Col>
								<Col xs={24} sm={24} md={8}>
									<Row>
										<Col xs={12} sm={12} md={9}>
											<Icon type={PhoneOutlined} className="text-primary font-size-md" />
											<span className="text-muted ml-2">Status:</span>
										</Col>
										<Col xs={12} sm={12} md={15}>
											<span className="font-weight-semibold">{bot?.status}</span>
										</Col>
									</Row>
								</Col>

								<Col xs={24} sm={24} md={8}>
									<p></p>
									<Flex alignItems="center" mobileFlex={false} className="mb-3 text-md-left text-center">
										{
											bot.status === 'qrcode' && <QRCodeCanvas
												value={bot?.qrcode}
												size={128}
												bgColor={"#ffffff"}
												fgColor={"#000000"}
												level={"L"}
												includeMargin={false}
												imageSettings={{
													src: "https://www.newanalysis.com.br/wp-content/uploads/2022/10/cropped-iconeana-32x32.png",
													x: undefined,
													y: undefined,
													height: 24,
													width: 24,
													excavate: true,
												}}
											/>
										}
									</Flex>

								</Col>
							</Row>
						</div>
					</div>
				</Col>
			</Row>

		</Card>
	)
}




const Profile = () => {

	const avatarSize = 150;

	return (
		<>
			<PageHeaderAlt background="/img/others/img-17.jpg" cssClass="bg-primary" overlap>
				<div className="container text-center">
					<div className="py-5 my-md-5">
					</div>
				</div>
			</PageHeaderAlt>
			<div className="container my-4">
				<ProfileInfo avatarSize={avatarSize} />
				{/* <Row gutter="16">
						<Col xs={24} sm={24} md={8}>
							<Connection />
							<Group />
						</Col>
						<Col xs={24} sm={24} md={16}>
							<Experiences />
							<Interested />
						</Col>
					</Row>  */}
			</div>
		</>
	)
}

export default Profile
