import antdPtBR from 'antd/es/locale/pt_BR';
import brMsg from "../locales/pt_BR.json";

const BrLang = {
  antd: antdPtBR,
  locale: 'pt-BR',
  messages: {
    ...brMsg
  },
};
export default BrLang;
