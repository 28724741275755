import fetch from 'auth/FetchInterceptor'

const operadorService = {}

operadorService.get = function () {
     return fetch({
          url: `/operador`,
          method: 'get',
     })
}



export default operadorService