import fetch from 'auth/FetchInterceptor'

const botService = {}

botService.getBot = function (id) {
     return fetch({
          url: `/stack/${id}`,
          method: 'get',
     })
}

botService.desligar = function (id) {
     return fetch({
          url: `/stack/${id}`,
          method: 'DELETE',
     })
}

botService.ligar = function (id) {
     return fetch({
          url: `/stack/${id}`,
          method: 'post',
     })
}

export default botService