import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/contatos/contatos`} component={lazy(() => import(`./contatos/contatos`))} />
        <Route path={`${APP_PREFIX_PATH}/contatos/grupos`} component={lazy(() => import(`./contatos/grupos`))} />
        <Route path={`${APP_PREFIX_PATH}/campanhas/listagem`} component={lazy(() => import(`./campanhas/campanhas`))} />
        <Route path={`${APP_PREFIX_PATH}/campanhas/canal`} component={lazy(() => import(`./campanhas/campanhasCanal`))} />
        <Route path={`${APP_PREFIX_PATH}/campanhas/logs`} component={lazy(() => import(`./campanhas/logEnvio`))} />
        <Route path={`${APP_PREFIX_PATH}/campanhas/sms-answer`} component={lazy(() => import(`./campanhas/smsResposta`))} />
        <Route path={`${APP_PREFIX_PATH}/canal/canais`} component={lazy(() => import(`./canal/canais`))} />
        <Route path={`${APP_PREFIX_PATH}/canal/grupos`} component={lazy(() => import(`./canal/grupos-canais`))} />
        <Route path={`${APP_PREFIX_PATH}/announcement/register/:id`} component={lazy(() => import(`./anuncio/cadastros_qrcode`))} />
        <Route path={`${APP_PREFIX_PATH}/announcement/register`} component={lazy(() => import(`./anuncio/cadastros_qrcode`))} />
        <Route path={`${APP_PREFIX_PATH}/announcement/reports`} component={lazy(() => import(`./anuncio/relatorios`))} />
        <Route path={`${APP_PREFIX_PATH}/config/logs`} component={lazy(() => import(`./config/logacesso`))} />
        <Route path={`${APP_PREFIX_PATH}/config/list`} component={lazy(() => import(`./config/setting`))} />
        <Route path={`${APP_PREFIX_PATH}/bot/new`} component={lazy(() => import(`./bot/fluxo`))} />
        <Route path={`${APP_PREFIX_PATH}/chat/:id`} component={lazy(() => import(`./chat`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);